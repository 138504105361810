import React from "react";

import { graphql } from "gatsby";

import { useTranslation } from "gatsby-plugin-react-i18next";

import WeeDataFoundation from "assets/icons/wee-data-foundation.svg";
import DataFoundation from "assets/images/data-foundation.svg";
import DataFoundationConceptualFlow from "components/DataFoundationConceptualFlow";
import DataFoundationTechnicalFlow from "components/DataFoundationTechnicalFlow";
import Divider from "components/Divider";
import Hero from "components/Hero";
import HowWeDoIt from "components/HowWeDoIt";
import Next from "components/Next";
import PageContainer from "components/PageContainer";
import SEO from "components/SEO";
import WhatWeOffer from "components/WhatWeOffer";

const DataFoundationPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Hero
        title="Data Foundation"
        divider={
          <Divider
            icon={<img src={WeeDataFoundation} alt="wee-data-foundation" />}
          />
        }
        img={
          <Hero.HeroImage
            src={DataFoundation}
            alt="brain-mesh-claims"
            loading="lazy"
          />
        }
      >
        {t(
          "Our first-in-class Data Foundation technology ensures the cleanliness, accuracy, and completeness of any health claims data."
        )}
        <br />
        <br />
        {t("It allows our clients to unlock the full potential of their data.")}
      </Hero>
      <WhatWeOffer
        offerText={t(
          "No matter the state of the data and the sources from which the data is collected, Qantev's Data Foundation helps clients achieve the following fundamental steps:"
        )}
        footer={
          <div style={{ marginTop: 128 }}>
            <DataFoundationConceptualFlow />
          </div>
        }
      />
      <HowWeDoIt
        dark
        description={t(
          "Qantev’s proprietary AI algorithms allow for the seamless integration of high-quality models, client data and industry wide information, to create a solid and responsive database."
        )}
      />
      <DataFoundationTechnicalFlow />
      <Next
        text={
          <>
            Network
            <br />
            Management
          </>
        }
        to="/product/network-management"
      />
    </PageContainer>
  );
};

export default DataFoundationPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Data Foundation" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["dataFoundation", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
