import React from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

import DFLine1 from "assets/images/df-line-1.svg";
import DFLine2 from "assets/images/df-line-2.svg";
import Divider from "components/Divider";

const Text = styled.p`
  color: #707070;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  width: 100%;

  @media (min-width: 1024px) {
    width: 60%;
  }
`;

const StepImage = styled.img`
  visibility: hidden;
  width: 0;

  &:first-child {
    width: auto;
    visibility: visible;
  }

  &last-child {
    margin-right: 0;
  }

  @media (min-width: 1024px) {
    visibility: visible;
    width: auto;
    margin-right: 24px;
  }
`;

const StepImageContainer = styled.div`
  margin-right: 64px;

  @media (min-width: 1024px) {
    flex: 1;
    margin-right: 0;
  }
`;

const StepContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DataFoundationConceptualFlow = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StepImageContainer>
        <StepImage src={DFLine1} alt="df-line-1" />
        <StepImage src={DFLine2} alt="df-line-2" />
        <StepImage src={DFLine1} alt="df-line-3" />
      </StepImageContainer>
      <StepContainer>
        <div>
          <Divider text={t("CLEANING")} color="#00033F" />
          <Text>
            {t(
              "Cleaning the data of bugs and defects to render the data set homogeneous."
            )}
          </Text>
        </div>
        <div>
          <Divider text={t("REFINEMENT")} color="#00033F" />
          <Text>
            {t(
              "Refining the data by inferring additional data points from existing information."
            )}
          </Text>
        </div>
        <div>
          <Divider text={t("ENRICHMENT")} color="#00033F" />
          <Text>
            {t(
              "Enriching the data by including additional information from real world data sources."
            )}
          </Text>
        </div>
      </StepContainer>
    </Wrapper>
  );
};

export default DataFoundationConceptualFlow;
