import React from "react";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

import DF1 from "assets/images/df1.svg";
import DF2 from "assets/images/df2.svg";
import DF3 from "assets/images/df3.svg";
import DF4 from "assets/images/df4.svg";
import DFP1 from "assets/images/dfp1.svg";
import DFP2 from "assets/images/dfp2.svg";
import DFP3 from "assets/images/dfp3.svg";
import DFP4 from "assets/images/dfp4.svg";
import ArrowSeparatedImages from "components/ArrowSeparatedImages";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4em calc(15vw / 2);
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(180deg, #e5e5e5, #fff);
  color: #00033f;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProcessingImage = styled.img`
  height: unset !important;

  @media (min-width: 551px) {
    height: inherit !important;
    min-width: 100px;
  }

  @media (min-width: 768px) {
    width: unset !important;
    height: 240px !important;
  }

  @media (min-width: 1280px) {
    height: 360px !important;
  }
`;

const ProcessingStepContainer = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 48px;
  width: max-content;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ProcessingStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;

  @media (min-width: 1024px) {
    justify-content: center;
    margin-top: 0;
  }

  &:nth-child(-n + 2) {
    @media (min-width: 640px) {
      margin-top: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }
`;

const ProcessingStepImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

const DataFoundationConceptualFlow = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ArrowSeparatedImages>
        <Column>
          <img src={DF1} alt="data-foundation-step-1" />
          <span style={{ textAlign: "center" }}>
            <Trans t={t} i18nKey="INSURER INTERNAL DATA">
              INSURER
              <br />
              INTERNAL DATA
            </Trans>
          </span>
        </Column>
        <Column>
          <img src={DF2} alt="data-foundation-step-2" />
          <span style={{ textAlign: "center" }}>
            <Trans t={t} i18nKey="QANTEV SECURE INFRASTRUCTURE">
              QANTEV SECURE
              <br />
              INFRASTRUCTURE
            </Trans>
          </span>
        </Column>
        <ProcessingImage src={DF3} alt="data-foundation-step-3" />
        <Column>
          <img src={DF4} alt="data-foundation-step-4" />
          <span style={{ textAlign: "center" }}>
            <Trans t={t} i18nKey="QANTEV PLATFORM">
              QANTEV
              <br />
              PLATFORM
            </Trans>
          </span>
        </Column>
      </ArrowSeparatedImages>
      <ProcessingStepContainer>
        <ProcessingStep>
          <ProcessingStepImage src={DFP1} alt="data-foundation-p-step-1" />
          <span>
            <Trans t={t} i18nKey="FEATURE ENGINEERING">
              FEATURE
              <br />
              ENGINEERING
            </Trans>
          </span>
        </ProcessingStep>
        <ProcessingStep>
          <ProcessingStepImage src={DFP2} alt="data-foundation-p-step-2" />
          <span>
            <Trans t={t} i18nKey="NATURAL LANGUAGE PROCESSING">
              NATURAL LANGUAGE
              <br />
              PROCESSING
            </Trans>
          </span>
        </ProcessingStep>
        <ProcessingStep>
          <ProcessingStepImage src={DFP3} alt="data-foundation-p-step-3" />
          <span>
            <Trans t={t} i18nKey="MISSING DATA INFERENCE">
              MISSING DATA
              <br />
              INFERENCE
            </Trans>
          </span>
        </ProcessingStep>
        <ProcessingStep>
          <ProcessingStepImage src={DFP4} alt="data-foundation-p-step-4" />
          <span>
            <Trans t={t} i18nKey="EXTERNAL DATA ENRICHMENT">
              EXTERNAL DATA
              <br />
              ENRICHMENT
            </Trans>
          </span>
        </ProcessingStep>
      </ProcessingStepContainer>
    </Wrapper>
  );
};

export default DataFoundationConceptualFlow;
